import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const EndfieldIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page endfield'} game="endfield">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Arknights: Endfield wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Arknights: Endfield. Check our guides, tier
          lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're waiting for the next CBT to start working on the database.
          </p>
        </div>
      </div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/arknights-endfield/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/endfield/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default EndfieldIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Arknights: Endfield Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Arknights: Endfield. Check our guides, tier lists and reviews for characters available in the game."
    game="endfield"
  />
);
